/** @jsxImportSource @emotion/react */
import Image from 'next/image';

import ArrowRight from '@stories/atoms/Icons/arrow_right.svg';

import type { CampaignCarouselFragment } from '@gql/hygraph/graphql';
import type { CssProps } from '@stories/@types/custom';

export type CampaignCard = CampaignCarouselFragment['campaigns'][0] & CssProps & { onClick?: () => void };

const CampaignCard: React.FC<CampaignCard> = ({ image, link, cssProps, onClick }) => {
  return (
    <div
      css={() => ({
        alignItems: 'center',
        background: '#FFF',
        border: '1px solid rgba(0, 0, 0, 0.10)',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingBottom: '0px',
        ...(cssProps as object)
      })}
    >
      <div
        css={() => ({
          alignItems: 'flex-start',
          aspectRatio: '1',
          borderRadius: '8px 8px 0px 0px',
          display: 'flex',
          flexShrink: '0',
          overflow: 'hidden',
          position: 'relative',
          width: '100%'
        })}
      >
        {image && (
          <Image
            alt={image.alt}
            fill={true}
            sizes="(min-width: 1024px) 250px, 284px"
            src={image.url}
            css={{ objectFit: 'cover' }}
          />
        )}
      </div>
      <a
        href={link.href || undefined}
        onClick={onClick}
        css={() => ({
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
          '::before': {
            backgroundColor: 'hsl(0 0% 0% / 0%)',
            bottom: '0',
            content: "''",
            left: '0',
            position: 'absolute',
            right: '0',
            top: '0',
            zIndex: 1
          }
        })}
      >
        <div
          className="card-title"
          css={(t) => ({
            color: t.color.black,
            fontWeight: 'bold',
            lineHeight: '120%'
          })}
        >
          {link.label}
        </div>
        <ArrowRight
          css={{
            flexShrink: '0',
            height: '24px',
            padding: '3px',
            width: '24px'
          }}
        />
      </a>
    </div>
  );
};

export default CampaignCard;
