/** @jsxImportSource @emotion/react */
import { createContext, createRef, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ArrowButton, defaultArrowButtonSize } from '@stories/atoms/ArrowButton';
import { media } from '@stories/theming/settings';

import { noScrollbarStyle } from '../../../common/cssUtilities';
import { sendGAEvent } from '../../../common/utlis';

import type theme from '@stories/theming/default';

/* CAROUSEL
 * Component for horizontal scrolling of a (long) list of elements. E.g. product hits, gifts, etc.
 * The elements are passed as children and not modified. Spacing should be set as padding.
 */

type CarouselProps = {
  children: React.ReactNode;
  carouselWidth?: keyof typeof theme.section.width;
  center?: boolean; // center the elements *if they don't fullfill the viewport
  seamless?: boolean; // (on mobile) remove left and right padding and use the whole viewport width
  slideGapCompensation?: number; // in pixel positive value
  type?: 'left' | 'right' | string; //type of carousel etc. with left/right arrow spacing for buyer guides or without
};

const CarouselContext = createContext<{
  carouselViewboxRef: React.RefObject<HTMLElement>;
  scrollContainerRef: React.RefObject<HTMLElement>;
  scrollPercent: number;
  slideGapCompensation?: number;
}>({
  carouselViewboxRef: createRef(),
  scrollContainerRef: createRef(),
  scrollPercent: 0,
  slideGapCompensation: 0
});

const ArrowControls: React.FC<{ type?: 'left' | 'right' | string }> = ({ type }) => {
  const {
    scrollContainerRef,
    carouselViewboxRef,
    scrollPercent,
    slideGapCompensation = 0
  } = useContext(CarouselContext);

  const getOnClickHandler =
    (operationFn: (scrollLeft: number, offset: number) => number, gaEventLabel: string) => () => {
      const scrollableElement = scrollContainerRef.current;
      const viewboxElement = carouselViewboxRef.current;

      if (!scrollableElement) {
        return;
      }

      if (!viewboxElement) {
        return;
      }

      const { scrollLeft } = scrollableElement;
      const { offsetWidth } = viewboxElement;

      scrollableElement.scroll({
        left: operationFn(operationFn(scrollLeft, offsetWidth), slideGapCompensation * 2),
        behavior: 'smooth'
      });

      sendGAEvent({
        category: 'Carousel',
        action: 'arrow click',
        label: gaEventLabel
      });
    };

  const disabledArrowCss = {
    filter: 'grayscale(1)',
    opacity: '.5',
    pointerEvents: 'none'
  } as const;

  return (
    <div css={{ display: 'none', [media('desktopLarge')]: { display: 'block' } }}>
      <ArrowButton
        onClick={getOnClickHandler((a, b) => a - b, 'left')}
        cssProps={{
          position: 'absolute',
          top: `calc(50% - ${defaultArrowButtonSize / 2}rem)`,
          left: type === 'left' ? '-23rem' : '-5rem',
          transform: 'rotate(180deg)',
          ...(scrollPercent === 0 && disabledArrowCss)
        }}
      />
      <ArrowButton
        onClick={getOnClickHandler((a, b) => a + b, 'right')}
        cssProps={{
          position: 'absolute',
          top: `calc(50% - ${defaultArrowButtonSize / 2}rem)`,
          right: type === 'right' ? '-22rem' : '-5rem',
          ...(scrollPercent === 100 && disabledArrowCss)
        }}
      />
    </div>
  );
};

const Carousel: React.FC<CarouselProps> = ({
  children,
  carouselWidth = 'regular',
  center = false,
  seamless = false,
  slideGapCompensation,
  type = ''
}) => {
  const carouselViewboxRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrollPercent, setScrollPercent] = useState(0);
  const [hasScrollableOverflow, setHasScrollableOverflow] = useState(false);

  const contextValue = useMemo(
    () => ({
      scrollContainerRef,
      carouselViewboxRef,
      slideGapCompensation,
      scrollPercent
    }),
    [scrollContainerRef, carouselViewboxRef, scrollPercent, slideGapCompensation]
  );

  useEffect(() => {
    const handleViewportResize = () => {
      if (!scrollContainerRef.current) {
        return;
      }

      const { scrollWidth, clientWidth } = scrollContainerRef.current;

      if (scrollWidth > clientWidth) {
        setHasScrollableOverflow(true);
      } else {
        setHasScrollableOverflow(false);
      }
    };

    const trackCarouselScroll = (event) => {
      const { clientWidth, scrollLeft, scrollWidth } = event.target;
      const scrolledPercent = Math.round((scrollLeft * 100.0) / (scrollWidth - clientWidth));
      if (scrolledPercent % 10 === 0) {
        setScrollPercent(scrolledPercent);
        sendGAEvent({
          category: 'Carousel',
          action: 'scrolled',
          label: `${scrolledPercent}%`
        });
      }
    };

    if (scrollContainerRef?.current) {
      scrollContainerRef?.current.addEventListener('scroll', trackCarouselScroll);
    }
    window.addEventListener('resize', handleViewportResize);
    handleViewportResize();
  }, []);

  return (
    <CarouselContext.Provider value={contextValue}>
      <div
        css={(t) => ({
          margin: 'auto',
          maxWidth: t.section.width[carouselWidth],
          position: 'relative',
          width: '100%',
          ...(seamless && {
            transform: `translateX(calc(-1 * var(--offset)))`,
            width: `calc(100% + 2 * var(--offset))`,
            [media('desktopLarge')]: {
              width: '100%',
              transform: 'none'
            }
          })
        })}
      >
        {hasScrollableOverflow && <ArrowControls type={type} />}
        <div ref={carouselViewboxRef} css={() => ({ overflow: 'hidden' })}>
          <div
            ref={scrollContainerRef}
            css={() => ({
              ...noScrollbarStyle,
              overflow: 'auto',
              ...(slideGapCompensation &&
                (seamless
                  ? {
                      [media('desktopLarge')]: {
                        marginLeft: `-${slideGapCompensation}px`,
                        marginRight: `-${slideGapCompensation}px`
                      }
                    }
                  : {
                      marginLeft: `-${slideGapCompensation}px`,
                      marginRight: `-${slideGapCompensation}px`
                    }))
            })}
          >
            <div
              css={() => ({
                ...(center && !hasScrollableOverflow && { justifyContent: 'center' }),
                ...(seamless && {
                  padding: `0 ${
                    (slideGapCompensation || 0) < 16 ? `${16 - (slideGapCompensation || 0)}px` : '1rem'
                  }`
                }),
                display: 'flex',
                '& > *': { flexShrink: 0, flexGrow: 0 },
                [media('desktop')]: {
                  padding: 0
                }
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </CarouselContext.Provider>
  );
};

export default Carousel;
export type { CarouselProps };
