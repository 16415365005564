/** @jsxImportSource @emotion/react */
import { useRef } from 'react';

import { useIntersection } from '@common/hooks/useIntersection';
import { sendGAEvent } from '@common/utlis';
import { container } from '@stories/atoms/Grid';
import { SectionStyled } from '@stories/atoms/Section/Section';
import CampaignCard from '@stories/molecules/CampaignCard';
import { media } from '@stories/theming/settings';

import Carousel from '../Carousel';

import type { CampaignCarouselFragment } from '@gql/hygraph/graphql';

const CampaignCarousel: React.FC<CampaignCarouselFragment> = ({ headline, campaigns, sectionStyle }) => {
  const sectionRef = useRef(null);
  // const itemsForTracking = campaigns?.map(({ link }) => ({ item_name: link.label }));
  useIntersection(sectionRef, () => {
    sendGAEvent({ category: 'Module Tracking', action: 'Module Impression', label: 'CampaignCarousel' });
  });
  return (
    <div ref={sectionRef}>
      <SectionStyled spacingSize="short" {...sectionStyle} headline={headline || undefined}>
        <div css={container}>
          <Carousel seamless slideGapCompensation={8}>
            {campaigns.map((campaignCard) => (
              <div
                key={campaignCard.link.href}
                css={{
                  padding: '0 .5rem',
                  width: '284px',
                  [media('desktop')]: { width: '25%' }
                }}
              >
                <CampaignCard
                  {...campaignCard}
                  cssProps={{ height: '100%' }}
                  onClick={() =>
                    sendGAEvent({
                      category: 'Module Tracking',
                      action: 'Module Click',
                      label: 'CampaignCarousel',
                      additionalTrackingParameters: {
                        value_1: `${campaignCard?.link.label}`
                      }
                    })
                  }
                ></CampaignCard>
              </div>
            ))}
          </Carousel>
        </div>
      </SectionStyled>
    </div>
  );
};

export default CampaignCarousel;
